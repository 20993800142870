import React from 'react'
import {
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  VStack,
  useColorMode,
} from '@chakra-ui/react'
import { RaffleTableItem } from './RaffleTableListItem'
import { raffleMinType } from '../../../techRaffles/types'
import { useOnChainRaffleListData } from '../../../techRaffles/hooks/raffle'
import { useWallet } from '@solana/wallet-adapter-react'

const RaffleTableList = (props: {
  raffles?: raffleMinType[]
  showMinimal?: boolean
  hideBuy?: boolean
}) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const wallet = useWallet()
  const combinedRaffleRes = useOnChainRaffleListData(
    props.raffles,
    wallet.publicKey
  )
  const showMinimal = props.showMinimal ?? false

  return (
    <TableContainer maxHeight='80vh' overflowX='unset' overflowY='scroll'>
      <Table variant='simple' size='sm'>
        <Thead fontFamily={'Inter'} position='sticky' top={0} zIndex='docked'>
          <Tr
            bg={isDarkMode ? 'backgroundBlack' : 'cardWhite'}
            color={isDarkMode ? 'white' : 'black'}
            borderY='1px solid cardBlack'
          >
            <Th
              px={2}
              justifyContent={'center'}
              textAlign='center'
              fontFamily='Inter'
            >
              Image
            </Th>
            <Th
              px={2}
              justifyContent={'center'}
              textAlign='center'
              fontFamily='Inter'
            >
              NFT info
            </Th>
            <Th
              px={2}
              justifyContent={'center'}
              textAlign='center'
              fontFamily='Inter'
            >
              Host
            </Th>
            {showMinimal ? (
              <Th
                px={2}
                justifyContent={'center'}
                textAlign='center'
                fontFamily='Inter'
              >
                Ending
              </Th>
            ) : (
              <>
                <Th
                  px={2}
                  justifyContent={'center'}
                  textAlign='center'
                  fontFamily='Inter'
                >
                  Stats
                </Th>
                <Th
                  px={2}
                  justifyContent={'center'}
                  textAlign='center'
                  fontFamily='Inter'
                >
                  Tickets
                </Th>
                {!props.hideBuy && (
                  <Th
                    px={2}
                    justifyContent={'center'}
                    textAlign='center'
                    fontFamily='Inter'
                  >
                    Buy
                  </Th>
                )}
                <Th
                  px={2}
                  justifyContent={'center'}
                  textAlign='center'
                  fontFamily='Inter'
                >
                  Your tickets
                </Th>
              </>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {(combinedRaffleRes.value ?? props.raffles ?? []).map((raffle) => (
            <RaffleTableItem
              key={raffle.id}
              raffle={raffle}
              showMinimal={showMinimal}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default RaffleTableList
