import {
  AspectRatio,
  Badge,
  Box,
  BoxProps,
  Flex,
  HStack,
  Image,
  Link,
  Spinner,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorMode,
  VStack,
} from '@chakra-ui/react'
import { useWallet } from '@solana/wallet-adapter-react'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import * as d from 'date-fns'
import NextLink from 'next/link'
import { useMemo, useState } from 'react'
import { HiOutlineTicket } from 'react-icons/hi'
import { compareTokensForSort } from '../../../../utils/tokenSortUtil'
import { formatFloatForDisplay } from '../../../../utils/utils'
import { BadgesList } from '../../../rewards/components/BadgesCard'
import { PlayerLvl } from '../../../rewards/components/PlayerLvl'
import {
  useOnChainRaffle,
  useOnChainRaffleUserData,
} from '../../../techRaffles/hooks/raffle'
import { hasRaffleEnded } from '../../../techRaffles/raffleUtils'
import {
  OnChainRaffleType,
  OnChainUserRaffleType,
  participantType,
  raffleMinType,
} from '../../../techRaffles/types'
import { BuyTicketsOnOverview } from '../BuyTicketsOnOverview'
import { EnterRewardRaffleOnOverview } from '../EnterRewardRaffleOnOverview'
import { ProfilePicture } from '../ProfilePicture'
import RaffleCountdown from '../RaffleCountdown'
import { RaffleStats } from '../RaffleStats'

export type combinedRaffleType = raffleMinType & {
  onChainData?: OnChainRaffleType | null
  onChainUserData?: OnChainUserRaffleType | null
  participantData?: participantType | null
}

type cardType = {
  w?: string | string[]
  h?: string
  minWidth?: BoxProps['minWidth']
  imgH?: string
  raffle: combinedRaffleType
  userId?: string
  showMoreRewards?: boolean
  showMinimal?: boolean
}

export const RaffleTableItem: (props: cardType) => JSX.Element = (
  props: cardType
) => {
  const showMinimal = props.showMinimal
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const {
    w = '100%',
    h = 'unset',
    imgH = 'unset',
    raffle,
    showMoreRewards,
  } = props
  const name = raffle.name
  const flaggedLabel = raffle.creatorUser?.hasBeenFlagged
    ? 'Flagged Raffler'
    : raffle.creatorProject && !raffle.creatorProject.verified
    ? 'Unverified Project'
    : undefined

  /* 
  verified, isRegular, flagged, ...
  
  const isRegularRaffler = false // (raffle.creatorUser?._count.createdRaffles ?? 0) > 4
  const verified = !!flaggedLabel
    ? false
    : raffle.creatorProject?.verified ??
      (raffle.creatorUser?.isTrustedRaffler || isRegularRaffler) ??
      false
  const isTrusted =
    raffle.creatorProject?.verified ?? raffle.creatorUser?.isTrustedRaffler
  const verifiedLabel = verified
    ? raffle.creatorProject?.verified
      ? 'Verified Project'
      : raffle.creatorUser?.isTrustedRaffler
      ? 'Trusted Raffler'
      : isRegularRaffler
      ? 'Regular Raffler'
      : undefined
    : undefined */
  const bgColorsDark = 'rgba(0, 0, 0, .5)'
  const bgColorsLight = 'rgba(255, 255, 255, .6)'
  const image =
    raffle.videoUrlMin ??
    raffle.imageUrlMin ??
    raffle.imageUrlMonetMin ??
    raffle.imageUrl
  const isNFTRaffle = raffle.type === 'NFT'
  const isWhitelistRaffle = raffle.type === 'WHITELIST'
  const isIRLRaffle = raffle.type === 'IRL'
  const owner = useMemo(() => {
    if (raffle.isRewardRaffle) return 'Monet Rewards'
    return (
      raffle.creatorProject?.communityName ??
      raffle.creatorUser?.name ??
      `${(raffle.creatorUser?.wallet ?? '').slice(0, 4)}...${(
        raffle.creatorUser?.wallet ?? ''
      ).slice(-4)}`
    )
  }, [raffle])
  const price = raffle.ticketPrice
  const priceToken = raffle.ticketPriceToken?.symbol
  const maxTickets = raffle.maxTickets
  const [imageIsHover, setImageIsHover] = useState(false)
  const wallet = useWallet()

  const [loadOnChainData, setLoadOnChainData] = useState(false)
  const internalOnChainRaffleRes = useOnChainRaffle(raffle.id, loadOnChainData)
  const onChainRaffleData = internalOnChainRaffleRes?.data ?? raffle.onChainData

  const [loadOnChainUserData, setLoadOnChainUserData] = useState(false)
  const internalOnChainUserDataRes = useOnChainRaffleUserData(
    onChainRaffleData,
    !loadOnChainUserData
  )
  const onChainUserData =
    internalOnChainUserDataRes?.data ?? raffle.onChainUserData

  const refetchOnChainData = async () => {
    setLoadOnChainData(true)
    setLoadOnChainUserData(true)
    const onChainDataResult = await internalOnChainRaffleRes.refetch()
    const onChainUserDataResult = await internalOnChainUserDataRes.refetch()
    return { onChainDataResult, onChainUserDataResult }
  }

  const totalTicketsSold = raffle.isRewardRaffle
    ? raffle.ticketsSoldFinal
    : onChainRaffleData?.ticketCount

  const [possiblyEnded, setPossiblyEnded] = useState(false)
  const hasEnded = useMemo(() => {
    return hasRaffleEnded(raffle)
  }, [raffle?.ends, possiblyEnded])

  const allowedPurchaseTokens = useMemo(() => {
    if (!raffle.allowedPurchaseTokens) return []
    return raffle.allowedPurchaseTokens.sort((a, b) =>
      compareTokensForSort(a.token, b.token)
    )
  }, [raffle.allowedPurchaseTokens])

  const userWon = useMemo(() => {
    return (
      !!wallet.publicKey &&
      onChainRaffleData?.winners.some((winner) =>
        winner.equals(wallet.publicKey!)
      )
    )
  }, [wallet, raffle])

  const timeEl = useMemo(() => {
    const today = new Date()

    if (hasEnded) {
      return (
        <Box
          // bg='rgba(255, 255, 255, 0.8)'
          bg='rgba(0, 0, 0, 0.8)'
          color='#fff'
          borderRadius='1rem'
          py='0.5rem'
          px='1rem'
          fontWeight='600'
          fontSize='0.75rem'
        >
          Ended at {d.format(raffle.ends, 'dd.MM.yyyy HH:mm')}
        </Box>
      )
    }

    if (raffle.status === 'SCHEDULED' || raffle.status === 'IN_CREATION') {
      if (!d.isAfter(today, raffle.starts) || raffle.status === 'IN_CREATION') {
        return (
          <Box
            bg='rgba(0, 0, 0, 0.8)'
            borderRadius='1rem'
            py='0.5rem'
            px='1rem'
            fontWeight='600'
            fontSize='0.75rem'
          >
            Upcoming
          </Box>
        )
      } else if (d.isBefore(today, raffle.ends)) {
        return (
          <Box w='fit-content'>
            <RaffleCountdown
              showEndDateInitially={!!showMinimal}
              variant='card'
              ends={raffle.ends}
              raffleEnded={() => {
                setPossiblyEnded(true)
              }}
            />
          </Box>
        )
      }
    }
  }, [raffle, hasEnded, showMinimal])

  const raffleTypeBoxBorder =
    colorMode === 'light'
      ? '1px solid #E9E9E9'
      : '1px solid rgba(255, 255, 255, 0.4)'

  return (
    <Tr>
      <Td px={2}>
        <NextLink
          passHref
          href={raffle.isRewardRaffle ? `` : `/r/${raffle.id}`}
          target='_blank'
        >
          <Link w='100%'>
            <Box overflow={'hidden'} w='80px'>
              <AspectRatio ratio={1}>
                <Image
                  w={'100%'}
                  sizes='100px'
                  h={imgH}
                  src={image}
                  borderRadius='12px'
                  fallback={
                    <Image
                      w={'100%'}
                      borderRadius='12px'
                      sizes='100px'
                      h={imgH}
                      src={raffle.imageUrl}
                      alt={raffle.name}
                      objectFit='cover'
                      transition={'transform .5s ease-in-out'}
                      _hover={{
                        transform: 'scale(1.05)',
                      }}
                    ></Image>
                  }
                  alt={raffle.name}
                  objectFit='cover'
                  transition={'transform .5s ease-in-out'}
                  _hover={{
                    transform: 'scale(1.05)',
                  }}
                ></Image>
              </AspectRatio>
            </Box>
          </Link>
        </NextLink>
      </Td>
      <Td px={2}>
        <NextLink
          passHref
          href={raffle.isRewardRaffle ? `` : `/r/${raffle.id}`}
          target='_blank'
        >
          <Link
            display='block'
            height='100%'
            marginY={wallet.publicKey ? '0' : '12px'}
          >
            <Text
              isTruncated
              whiteSpace='normal'
              maxWidth='8rem'
              fontSize='0.9rem'
              fontWeight={600}
            >
              {name}
            </Text>
          </Link>
        </NextLink>
        {raffle.type === 'NFT' && !!raffle.collection?.name && (
          <>
            <NextLink
              passHref
              href={`/collections/${raffle.collection?.name}`}
              target='_blank'
            >
              <Link pt='.6rem'>
                <Flex alignItems={'center'}>
                  <Image
                    display='inline'
                    mr='4px'
                    w='16px'
                    h='16px'
                    src='/twitter-images/verified/verified.png'
                  ></Image>
                  <Text
                    maxWidth='7rem'
                    isTruncated
                    opacity={0.8}
                    fontSize={'0.875rem'}
                  >
                    {raffle.collection?.title}
                  </Text>
                </Flex>
              </Link>
            </NextLink>
          </>
        )}

        <Box borderRadius='1.5rem'>
          {isWhitelistRaffle && (
            <HStack paddingTop='.5rem' gap='0.25rem'>
              <HiOutlineTicket color={isDarkMode ? 'white' : 'black'} />
              <Text m={'0 !important'} fontWeight='500' fontSize='0.825rem'>
                WL
              </Text>
            </HStack>
          )}

          {isIRLRaffle && (
            <HStack paddingTop='.5rem' gap='0.25rem'>
              <HiOutlineTicket color={isDarkMode ? 'white' : 'black'} />
              <Text m={'0 !important'} fontWeight='500' fontSize='0.825rem'>
                IRL
              </Text>
            </HStack>
          )}
        </Box>
      </Td>
      <Td px={2}>
        <VStack spacing='5px' w='100%' justifyContent={'center'}>
          <NextLink
            passHref
            href={
              raffle.isRewardRaffle
                ? '#'
                : raffle.isUserRaffle
                ? `/u/${raffle.creatorUser!.wallet}`
                : `/p/${raffle.creatorProject!.publicId}`
            }
            target='_blank'
          >
            <Link fontSize='.875rem' w='100%'>
              <Flex align='center' gap={2} justifyContent='center'>
                {!raffle.isRewardRaffle && (
                  <ProfilePicture
                    imageUrl={
                      raffle.isUserRaffle
                        ? raffle.creatorUser?.profilePictureUrl
                        : raffle.creatorProject?.profilePictureUrl
                    }
                    gradientstart={
                      raffle.isUserRaffle
                        ? raffle.creatorUser!.gradientStart
                        : raffle.creatorProject!.gradientStart
                    }
                    gradientend={
                      raffle.isUserRaffle
                        ? raffle.creatorUser!.gradientEnd
                        : raffle.creatorProject!.gradientEnd
                    }
                    w='2rem'
                    h='2rem'
                    minW='2rem'
                    rounded='full'
                  />
                )}

                {raffle.isRewardRaffle && (
                  <Box
                    minW='2rem'
                    w='2rem'
                    h='2rem'
                    rounded='full'
                    background={colorMode === 'dark' ? 'white' : 'offblack'}
                    display='flex'
                    justifyContent='center'
                    alignItems={'center'}
                  >
                    <Text
                      fontWeight='900'
                      fontSize={'1.1rem'}
                      fontStyle='normal'
                      fontFamily='PlayfairBlack'
                      textAlign='center'
                      color={colorMode === 'dark' ? 'black' : '#fff'}
                    >
                      M
                    </Text>
                  </Box>
                )}

                {raffle.isRewardRaffle ? (
                  <Text isTruncated minW='6rem' maxW='8rem'>
                    {owner}
                  </Text>
                ) : (
                  <Text isTruncated minW='6rem' maxW='8rem'>
                    {owner}
                  </Text>
                )}
              </Flex>
            </Link>
          </NextLink>
          {!showMinimal && (
            <Flex w='100%' justifyContent={'center'}>
              <HStack spacing='3px' justifyContent='flex-end'>
                {raffle.creatorProject?.verified && (
                  <Tooltip label={'Verified Project'}>
                    <Image
                      w='25px'
                      m={'-6px !important'}
                      ml={'6px !important'}
                      src={`/images/rewards/badges/project.svg`}
                    />
                  </Tooltip>
                )}
                {/*verifiedLabel && (
    <Tooltip label={verifiedLabel}>
      <Text as='span'>
        <BsFillPatchCheckFill
          color={isTrusted ? 'green' : 'gray'}
        />
      </Text>
    </Tooltip>
  )*/}
                {raffle.isUserRaffle &&
                  raffle.creatorUser &&
                  (raffle.creatorUser.experiencePoints < 10 ? (
                    <Tooltip label='New user'>
                      <Image
                        w='36px'
                        m={'-5px !important'}
                        ml={'0px !important'}
                        src={`/images/rewards/badges/new.svg`}
                      />
                    </Tooltip>
                  ) : (
                    <Flex w='24px'>
                      <PlayerLvl
                        experiencePoints={raffle.creatorUser?.experiencePoints}
                      ></PlayerLvl>
                    </Flex>
                  ))}
                {raffle.isUserRaffle &&
                  (raffle.creatorUser?.badges &&
                  raffle.creatorUser.badges.length > 2 ? (
                    <HStack ml={1} h='40px' paddingY='2px'>
                      <BadgesList
                        imageW='36px'
                        m={'-5px !important'}
                        badges={raffle.creatorUser?.badges}
                      ></BadgesList>
                    </HStack>
                  ) : (
                    <BadgesList
                      imageW='36px'
                      m={'-5px !important'}
                      badges={raffle.creatorUser?.badges}
                    ></BadgesList>
                  ))}
              </HStack>
            </Flex>
          )}
        </VStack>
      </Td>
      {showMinimal ? (
        <Td px={2}>{timeEl}</Td>
      ) : (
        <>
          <Td px={2}>
            <VStack spacing='.5rem'>
              {raffle.collection && raffle.collection.floorPrice && (
                <Box>
                  <Badge
                    textTransform={'none'}
                    border={
                      isDarkMode ? 'solid 1px #BDBDBD' : 'solid 1px #dadada'
                    }
                    bg={colorMode === 'light' ? bgColorsLight : bgColorsDark}
                    color={isDarkMode ? 'white' : 'black'}
                    fontWeight={600}
                    size={'sm'}
                    px='6px'
                    py='2px'
                    mr='6px'
                    fontSize='.625rem'
                    borderRadius={'full'}
                  >
                    FP:{' '}
                    {formatFloatForDisplay(
                      raffle.collection.floorPrice / LAMPORTS_PER_SOL
                    )}
                    &#9678;
                    {/*false && !!raffle.collection?.averagePrice24hr && (
                      <>
                        {' '}
                        | AVG:{' '}
                        {formatFloatForDisplay(
                          (raffle?.collection?.averagePrice24hr ?? 0) / LAMPORTS_PER_SOL
                        )}
                        &#9678;
                      </>
                        )*/}
                  </Badge>
                </Box>
              )}

              {onChainRaffleData &&
                raffle.estimateTicketPriceInSol &&
                allowedPurchaseTokens.some((token) => token.token.onDEX) && (
                  <Box>
                    <Badge
                      textTransform={'none'}
                      border={
                        isDarkMode ? 'solid 1px #BDBDBD' : 'solid 1px #dadada'
                      }
                      bg={colorMode === 'light' ? bgColorsLight : bgColorsDark}
                      color={isDarkMode ? 'white' : 'black'}
                      fontWeight={600}
                      size={'sm'}
                      px='6px'
                      py='2px'
                      borderRadius={'full'}
                      fontSize='.625rem'
                    >
                      Vol:{' '}
                      {formatFloatForDisplay(
                        raffle.estimateTicketPriceInSol *
                          onChainRaffleData.ticketCount
                      )}
                      &#9678;
                      {raffle.maxTickets && (
                        <>
                          {' '}
                          | TTV:{' '}
                          {formatFloatForDisplay(
                            raffle.estimateTicketPriceInSol * raffle.maxTickets
                          )}
                          &#9678;
                        </>
                      )}
                    </Badge>
                  </Box>
                )}
              <Flex justifyContent='center' w='100%'>
                {timeEl}
              </Flex>
            </VStack>
          </Td>
          <Td px={2}>
            <VStack>
              <Box w='100%' maxW='18rem'>
                {userWon ? (
                  <Box>
                    <Text fontSize='1.5rem' fontWeight='700'>
                      You won! 🎉
                    </Text>
                  </Box>
                ) : totalTicketsSold &&
                  maxTickets &&
                  totalTicketsSold >= maxTickets ? (
                  <Box>
                    <Text fontWeight='700' fontSize='1.2rem' color='#f13a3a'>
                      Sold out 🔥
                    </Text>
                    <Text fontWeight='400' fontSize='0.75rem' color='#BDBDBD'>
                      {totalTicketsSold} Tickets sold
                    </Text>
                  </Box>
                ) : (
                  <Box
                    border={
                      isDarkMode ? 'solid 1px #BDBDBD' : 'solid 1px #dadada'
                    }
                    bg={colorMode === 'light' ? bgColorsLight : bgColorsDark}
                    borderRadius='full'
                    display='flex'
                    paddingY='.5rem'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Text fontWeight='500' fontSize='1rem'>
                      {totalTicketsSold ?? <Spinner size='sm' mt='0.3rem' />}{' '}
                      {maxTickets && <span>/ {maxTickets}</span>}
                    </Text>
                    {/*<Text
                  fontWeight='400'
                  fontSize='0.75rem'
                  color='#BDBDBD'
                  textAlign='right'
                >
                  Tickets sold
            </Text>*/}
                  </Box>
                )}
              </Box>

              <Box w='100%' maxW='18rem'>
                {raffle.isRewardRaffle ? (
                  <Box
                    color='#FFC728'
                    bg={isDarkMode ? 'primaryDark' : '#FFF8E4'}
                    h='40px'
                    w='120px'
                    border={
                      isDarkMode ? 'solid 1px #FFffff50' : 'solid 1px #FFDBB9'
                    }
                    borderRadius='full'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    fontWeight='bold'
                    gap='2'
                  >
                    <Image src='/images/rewards/GemsShine.svg' />
                    <Text color={isDarkMode ? 'white' : '#FF8F28'}>
                      {formatFloatForDisplay(price)}
                    </Text>
                  </Box>
                ) : (
                  <Box
                    border={
                      isDarkMode ? 'solid 1px #BDBDBD' : 'solid 1px #dadada'
                    }
                    bg={colorMode === 'light' ? bgColorsLight : bgColorsDark}
                    paddingY='.5rem'
                    borderRadius='full'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Text fontWeight='500' fontSize='1rem' textAlign={'right'}>
                      {price} {priceToken}
                    </Text>
                    {/*<Text
                  fontWeight='400'
                  fontSize='0.75rem'
                  color='#BDBDBD'
                  textAlign='right'
                >
                  Ticket Price
            </Text>*/}
                  </Box>
                )}
                {hasEnded && (
                  <Box mt='1rem' px='1rem'>
                    {raffle.status === 'SCHEDULED' && hasEnded && (
                      <Text
                        fontSize='1rem'
                        fontWeight={600}
                        textAlign={'center'}
                      >
                        Draw pending
                      </Text>
                    )}
                    {raffle.winners.map((w) => (
                      <Text
                        key={w.id}
                        fontSize='1rem'
                        fontWeight={600}
                        textAlign={'center'}
                        isTruncated
                      >
                        🎉 Winner:{' '}
                        <Link
                          display='inline'
                          target={'_blank'}
                          textDecor={'underline'}
                          href={`/u/${w.wallet}`}
                        >
                          {w.name}
                        </Link>
                      </Text>
                    ))}
                  </Box>
                )}
              </Box>
            </VStack>
          </Td>
          {raffle.status === 'SCHEDULED' && !hasEnded && (<Td px={2}>
            
              <>
                {raffle.isRewardRaffle ? (
                  <EnterRewardRaffleOnOverview
                    raffle={raffle}
                    participantData={raffle.participantData}
                    userId={props.userId}
                    variant='table'
                  ></EnterRewardRaffleOnOverview>
                ) : (
                  wallet.publicKey && (
                    <BuyTicketsOnOverview
                      raffle={raffle}
                      onChainUserData={onChainUserData ?? null}
                      onChainRaffleRes={onChainRaffleData ?? null}
                      refetchOnChainData={refetchOnChainData}
                      totalTicketsSold={totalTicketsSold ?? 0}
                      variant='table'
                    />
                  )
                )}
              </>
          </Td>
            )}
          <Td px={2}>
            <RaffleStats
              raffle={raffle}
              onChainUserData={onChainUserData ?? null}
              onChainRaffleRes={onChainRaffleData ?? null}
            />
          </Td>
        </>
      )}
      {/*
          <Flex
            justify='space-between'
            px='.7rem'
            alignItems='baseline'
            mb='.4rem'
          >
            <SaveRaffleButton raffleId={raffle.id} />
              </Flex>*/}
      {/* Multiplier --
               {raffle.pointsMultiplier > 1 && (
              <Flex h={'100%'} justifyContent={'center'} alignItems='center'>
                <HStack
                  paddingY={1}
                  paddingX={2}
                  borderRadius={'full'}
                  border={'1px solid #FF8F28'}
                >
                  <Text m={'0 !important'} fontWeight={500}>
                    {' '}
                    {formatFloatForDisplay(raffle.pointsMultiplier)}x
                  </Text>
                  <Image src='/images/rewards/GemsShine.svg' />
                </HStack>
              </Flex>
            )} */}
    </Tr>
  )
}

export default RaffleTableItem
